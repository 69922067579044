import React, { useState } from 'react';
import footerStyles from '../../styles/components/layouts/footer.module.css';
import styles from '../../styles/styles.module.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [expandInformation, setExpandInformation] = useState(false);
  const [expandContact, setExpandContact] = useState(false);
  const [expandServices, setExpandServices] = useState(false);

  const showInformation = () => {
    setExpandInformation(!expandInformation);
  };

  const showService = () => {
    setExpandServices(!expandServices);
  };

  const showContact = () => {
    setExpandContact(!expandContact);
  };

  return (
    <footer className={footerStyles.footer}>
      <section className={`${styles.grid} ${footerStyles.grid__1x4}`}>
        <section className={footerStyles.footer__section}>
          <Link to="/">
            <img
              src="/assets/iditium-logo-white.png"
              alt="Elevate your digital presence with Iditium's sleek and innovative solutions, representing excellence 
                            in website development, mobile apps, and cloud infrastructure solutions"
              className={footerStyles.footer__logo}
            />
          </Link>

          <p className={footerStyles.footer__paragraph}>
            Iditium crafts your digital dreams into reality. Websites that
            captivate, mobile apps that engage, software that solves, and cloud
            that scales with you.
          </p>

          <div className={footerStyles.footer__icons}>
            <Link to="https://www.instagram.com/iditium_development/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={styles.social__icon}
              >
                <use xlinkHref="/assets/social-media-icons.svg#facebook-white"></use>
              </svg>
            </Link>
            <Link to="https://www.instagram.com/iditium_development/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={styles.social__icon}
              >
                <use xlinkHref="/assets/social-media-icons.svg#twitter-x-white"></use>
              </svg>
            </Link>
            <Link to="https://stackoverflow.com/users/23253763/iditium">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={styles.social__icon}
              >
                <use xlinkHref="/assets/social-media-icons.svg#stack-overflow-white"></use>
              </svg>
            </Link>
            <Link to="https://www.linkedin.com/in/iditium-development-715a261b8/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={styles.social__icon}
              >
                <use xlinkHref="/assets/social-media-icons.svg#linkedin-icon-white"></use>
              </svg>
            </Link>
          </div>
        </section>

        <section
          className={`${footerStyles.footer__section} ${
            expandInformation ? footerStyles.collapsible__expanded : ''
          }`}
        >
          <div className={styles.row} onClick={showInformation}>
            <h2 className={footerStyles.footer__header}>Links</h2>
            <svg
              className={`${styles.icon} ${footerStyles.footer__icon} ${footerStyles.collapsible__chevron}`}
            >
              <use href="/assets/sprite.svg#chevron"></use>
            </svg>
          </div>

          <div className={footerStyles.collapsible__content}>
            <ul className={styles.list}>
              <FooterLink linkName={'Home'} url={'/'} />
              <FooterLink linkName={'About Us'} url={'/about-us'} />
              <FooterLink linkName={'Contact Us'} url={'/contact-us'} />
            </ul>
          </div>
        </section>

        <section
          className={`${footerStyles.footer__section} ${
            expandServices ? footerStyles.collapsible__expanded : ''
          }`}
        >
          <div className={styles.row} onClick={showService}>
            <h2 className={footerStyles.footer__header}>Information</h2>
            <svg
              className={`${styles.icon} ${footerStyles.footer__icon} ${footerStyles.collapsible__chevron}`}
            >
              <use href="/assets/sprite.svg#chevron"></use>
            </svg>
          </div>

          <div className={footerStyles.collapsible__content}>
            <ul className={styles.list}>
              <FooterLink linkName={'Terms & Conditions'} url={'/'} />
              <FooterLink linkName={'Private Policy'} url={'/'} />
              <FooterLink linkName={'Referral Program'} url={'/referral-program'} />
            </ul>
          </div>
        </section>

        <section
          className={`${footerStyles.footer__section} ${
            expandContact ? footerStyles.collapsible__expanded : ''
          }`}
        >
          <div className={styles.row} onClick={showContact}>
            <h2 className={footerStyles.footer__header}>Contact Us</h2>
            <svg
              className={`${styles.icon} ${footerStyles.footer__icon} ${footerStyles.collapsible__chevron}`}
            >
              <use href="/assets/sprite.svg#chevron"></use>
            </svg>
          </div>

          <div className={footerStyles.collapsible__content}>
            <ul className={styles.list}>
              <FooterLink linkName={'Blogs'} url={'/'} />
              <FooterLink linkName={'Get In Touch'} url={'/contact-us#form'} />
            </ul>
          </div>
        </section>
      </section>
    </footer>
  );
};

function FooterLink(props: { linkName: string; url: string }) {
  return (
    <Link to={props.url}>
      <li className={footerStyles.footer__item}>
        <svg className={footerStyles.footer__link}>
          <use href="/assets/sprite.svg#double-arrows"></use>
        </svg>
        {props.linkName}
      </li>
    </Link>
  );
}

export default Footer;
