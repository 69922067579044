import React, { FormEvent, useState } from 'react';
import MetaData from '../components/layouts/meta-data';
import HeroComponent from '../components/hero';
import Button from '../components/inputs/button';
import styles from '../styles/styles.module.css';
import homeStyles from '../styles/pages/home.module.css';
import contactStyles from '../styles/pages/contact-us.module.css';
import { showFailureToast, showSuccessToast } from '../utils/Notification';
import { STATUS_OK } from './contact-us';
import { isValidEmail, isValidPhoneNumber } from '../utils/Validation';
import emailjs from 'emailjs-com';
import FeatureBenefit from '../components/feature-benefit';
import FAQComponent from '../components/frequently-asked-questions';
import Footer from '../components/layouts/footer';
// import { useNavigate } from 'react-router-dom';

const ReferralProgram = () => {
    // const navigate = useNavigate();
    const [error, setError] = useState<string>('');
    const [contactDetails, setContactDetails] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        subject: 'Referral Program',
        details: ''
    });
    const { name, email, phoneNumber, subject, details } = contactDetails;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function onChangeValue(e: any) {
        setContactDetails({ ...contactDetails, [e.target.name]: e.target.value });
    }

    const submitHandler = async (e: FormEvent) => {
        e.preventDefault();

        if (!name) {
            setError('Name is required');
            return;
        }

        if (!isValidEmail(email)) {
            setError('Please ensure your email is correct');
            return;
        }

        if (!isValidPhoneNumber(phoneNumber)) {
            setError('Please ensure your phone number is correct');
            return;
        }

        const formData = {
            name: name,
            email: email,
            phoneNumber: phoneNumber,
            subject: subject,
            details: details
        };

        const result = await sendEmail(formData);

        if (result) {
            setContactDetails({
                name: '',
                email: '',
                phoneNumber: '',
                subject: 'Referral Program',
                details: ''
            });
        }
    };

    function navigateToReferralContactUs() {
        // navigate('/referral-form#referralForm', { });
        setTimeout(() => {
            // eslint-disable-next-line no-undef
            const element = document.getElementById('referralForm');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    }

    async function sendEmail(formInfo: any) {
        const result = await emailjs.send(
            process.env.REACT_APP_SERVICE_ID ?? '',
            process.env.REACT_APP_TEMPLATE_ID ?? '',
            formInfo,
            process.env.REACT_APP_USER_ID ?? '');

        if (result.status === STATUS_OK) {
            showSuccessToast('Success, we will be in touch');
            return true;
        }

        showFailureToast('Oops, something went wrong out our side. Try again.');
        return false;
    }

    return (
        <main>
            <MetaData
                title={'Referral Program - Iditium'}
                description={`
                    Get in touch with Iditium for expert website development, mobile app creation, 
                    cloud solutions, custom software, and consulting services. We're here to help you achieve your digital goals.
                `}
            />

            <HeroComponent heroText={'Join Our Referral Program'} />

            <section
                className={`${styles.grid} ${styles.grid__columns} ${homeStyles.software__container}`}
            >
                <div data-aos="flip-up" className={homeStyles.column__1}>
                    <img
                        src="/assets/iditium-information.png"
                        className={homeStyles.information__img}
                        alt="Empower your global presence with Iditium's expertise in web 
                        development, mobile apps, and cloud infrastructure solutions."
                    />
                </div>

                <div data-aos="flip-up" className={homeStyles.column__2}>
                    <h3>Referral Program</h3>
                    <h2>
                        <span className={homeStyles.black__text}>Share the Opportunity, </span>
                        Reap the Rewards
                    </h2>
                    <p>
                        Joining our referral program is simple and rewarding! Share the opportunity to work with us,
                        and for every client you refer who uses our software development services, you’ll earn exclusive rewards.
                    </p>
                    <Button
                        title={'Learn More'}
                        onClick={navigateToReferralContactUs}
                        buttonType={'primary'}
                    />
                </div>
            </section>

            <section
                className={`
            ${homeStyles.web__development__container}
            ${styles.grid} 
            ${styles.grid__1x2} 
        `}
            >
                <div
                    data-aos="zoom-in-right"
                    className={`${homeStyles.padding_top} ${styles.center__content} ${styles.reverse__layout}`}
                >
                    <img
                        src="/assets/web-development.png"
                        className={homeStyles.feature__img}
                        alt="Iditium's skilled team at work—crafting exceptional 
                        websites through expert design and development for your digital success."
                    />
                </div>

                <div
                    data-aos="zoom-in-left"
                    className={`${styles.row} ${homeStyles.row__reverse} ${homeStyles.text__right}`}
                >
                    <p className={homeStyles.feature__number}>01</p>

                    <div className={styles.column}>
                        <h3 className={homeStyles.feature__heading}>How It Works</h3>
                        <p className={homeStyles.feature__paragraph}>
                            Joining our referral program is simple and rewarding! Share the opportunity to work with us,
                            and for every client you refer who uses our software development services, you’ll earn exclusive rewards.
                        </p>
                        <FeatureBenefit
                            title={'Reach Out'}
                            // eslint-disable-next-line max-len
                            description={
                                'Fill out the form below to express your interest in our referral program.'
                            }
                            reversed={true}
                        />
                        <FeatureBenefit
                            title={'Share the Details'}
                            // eslint-disable-next-line max-len
                            description={
                                'Provide the contact details of your referral, or share your unique referral code with them.'
                            }
                            reversed={true}
                        />
                        <FeatureBenefit
                            title={'Earn Rewards'}
                            // eslint-disable-next-line max-len
                            description={
                                'Once your referral becomes a client, you’ll receive a reward for every project they undertake with us.'
                            }
                            reversed={true}
                        />
                        <Button
                            title={'START'}
                            onClick={navigateToReferralContactUs}
                            buttonType={'feature'}
                            classNames={homeStyles.btn__right}
                        />
                    </div>
                </div>
            </section>

            <section
                className={`${styles.padding__container} ${styles.grid} ${styles.grid__1x2}`}
            >
                <div data-aos="zoom-in-right" className={styles.row}>
                    <p className={homeStyles.feature__number}>02</p>

                    <div className={styles.column}>
                        <h3 className={homeStyles.feature__heading}>Reward Details</h3>
                        <p className={homeStyles.feature__paragraph}>
                            We value your efforts in spreading the word about our services, which is why we’ve designed rewards that truly benefit you.
                        </p>
                        <FeatureBenefit
                            title={'Monetary Bonuses'}
                            description={
                                'Earn a cash reward for every new client who signs a project with us'
                            }
                            reversed={false}
                        />
                        <FeatureBenefit
                            title={'Service Discounts'}
                            // eslint-disable-next-line max-len
                            description={
                                'Get discounted rates on your next development project with us.'
                            }
                            reversed={false}
                        />
                        <FeatureBenefit
                            title={'Exclusive Perks'}
                            // eslint-disable-next-line max-len
                            description={
                                'Be the first to access new services and special offers.'
                            }
                            reversed={false}
                        />
                        <Button
                            title={'START'}
                            onClick={navigateToReferralContactUs}
                            buttonType={'feature'}
                            classNames={homeStyles.btn__left}
                        />
                    </div>
                </div>
                <div
                    data-aos="zoom-in-left"
                    className={`${styles.center__content} ${homeStyles.padding_top}`}
                >
                    <img
                        src="/assets/mobile-development.png"
                        className={homeStyles.feature__img}
                        alt="Iditium's dynamic team in action, shaping a cutting-edge mobile 
                        app through seamless design and development for a standout user experience."
                    />
                </div>
            </section>

            <section className={`${contactStyles.questions__container} ${styles.center__content} ${styles.text__center}`}>
                <h2>
                    Get Started with Our Referral Program</h2>
                <p>
                    Ready to earn rewards for connecting us with new clients? Fill out the form below to join our referral
                    program or to ask any questions you may have. Whether you're referring a friend,
                    a colleague, or a business partner, we’re here to guide you every step of the way. Let’s grow together!
                </p>

                <section id="referralForm" className={`${styles.grid} ${styles.grid__1x2}`}>
                    <div data-aos="flip-left" className={contactStyles.questions__img}>
                        <img
                            src='/assets/contact-us-image.png'
                            className={contactStyles.contact__img}
                            alt="Enhance your online presence with our professional website, mobile app, 
                            and cloud infrastructure solutions. Our dedicated team, depicted in this image, efficiently handles emails and calls, 
                            ensuring seamless communication and superior customer engagement for your business" />
                    </div>

                    <form data-aos="flip-right" className={contactStyles.questions__form} onSubmit={submitHandler}>
                        <input
                            type="name"
                            placeholder='Full Name'
                            name='name'
                            className={contactStyles.contact__input}
                            value={name}
                            onChange={onChangeValue}
                        />
                        <input
                            type="email"
                            placeholder='Email Address'
                            name='email'
                            className={contactStyles.contact__input}
                            value={email}
                            onChange={onChangeValue}
                        />
                        <input
                            type="text"
                            placeholder='Telephone Number'
                            name='phoneNumber'
                            className={contactStyles.contact__input}
                            value={phoneNumber}
                            onChange={onChangeValue}
                        />
                        <input
                            type="text"
                            placeholder='Subject'
                            name='subject'
                            className={contactStyles.contact__input}
                            value={subject}
                            onChange={onChangeValue}
                        />
                        <textarea
                            placeholder='Write Details'
                            name='details'
                            className={`${contactStyles.contact__input} ${contactStyles.contact__textarea}`}
                            value={details}
                            onChange={onChangeValue}
                        />
                        <Button
                            type={'submit'}
                            title={'Submit'}
                            onClick={() => { }}
                            buttonType={'primary'}
                            classNames={contactStyles.contact__button}
                            maxWidth={true}
                        />
                    </form>
                    {/* eslint-disable-next-line no-extra-parens */}
                    {error && (<p className={contactStyles.error__text}>{error}</p>)}
                </section>
            </section>

            {/* FAQ */}
            <section className={styles.padding__container}>
                <h2 className={styles.text__center}>Frequently Asked Questions</h2>
                <p className={`${styles.text__center} ${styles.padding__text}`}>
                    We’ve answered some common questions to make joining our referral program as seamless as possible.
                </p>

                <div className={`${styles.column} ${styles.center__content}`}>

                    <FAQComponent
                        question={'When will I receive my reward?'}
                        answer={`Rewards are issued within 14 days of the referral’s first payment.`}
                    />
                    <FAQComponent
                        question={'How do I track my referrals?'}
                        answer={`We’ll keep you updated via email with the status of your referrals.
`}
                    />
                    <FAQComponent
                        question={'How much can I earn per referral?'}
                        answer={`The reward amount depends on the size and scope of the project. Contact us for details.`}
                    />
                </div>
            </section>

            <Footer />
        </main>
    );
};

export default ReferralProgram;